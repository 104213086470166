.imprint-copy {

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  p {
    font-size: $fs-small;
    line-height: 1.6;
  }
}

