header.header {
  width: 100%;
  position: fixed;
  background-color: $c-white;
  z-index: 99;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background-color: $c-black;
    @include transition($t-long $ease-out-quad);
    @include transition-delay($t-medium);

    @media (min-width: $screen-md) {
      display: none;
    }
  }

  @media (min-width: $screen-md) {
    position: absolute;
    background-color: transparent;
  }
}


body.menu-open {
  header.header {
    &:after {
      width: 0;
    }
  }
}

