main > section {
  padding-top: 25vh;

  //@media (min-width: $screen-lg) {
  //  padding-top: 33vh;
  //}
}

.copy {
  visibility: hidden;
}