// RESPONSIVE TYPOGRAPHY

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@mixin responsive-font-size($minFontSize, $maxFontSize, $minScreenSize, $maxScreenSize) {
  $minFontSizeUnitless: strip-unit($minFontSize);
  $minScreenSizeUnitless: strip-unit($minScreenSize);
  $maxScreenSizeUnitless: strip-unit($maxScreenSize);
  $maxFontSizeUnitless: strip-unit($maxFontSize);

  font-size: calc(#{$minFontSize} + (#{$maxFontSizeUnitless} - #{$minFontSizeUnitless}) * (100vw - #{$minScreenSize}) / (#{$maxScreenSizeUnitless} - #{$minScreenSizeUnitless}));

  @media (min-width: $maxScreenSize) {
    font-size: $maxFontSize;
  }
  @media (max-width: $minScreenSize) {
    font-size: $minFontSize;
  }

}

