.loader-wrapper {
  position: fixed;
  background-color: $c-white;
  z-index: 9;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: none;
  @include transition($t-long $ease-out-quad);

  &.active {
    display: block;
  }
}

.loader {
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid $c-blue; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  @include translate(-50%, -50%);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}