.cursor {
  position: absolute;
  width: 3rem;
  height: 4.5rem;
  background-color: $c-blue;
  top: 0;

  @media (min-width: $screen-sm) {
    width: 4.5rem;
    height: 7.5rem;
  }

  @media (min-width: $screen-md) {
    width: 5.5rem;
    height: 8.5rem;
  }

  @media (min-width: $screen-lg) {
    width: 6.5rem;
    height: 10.5rem;
  }

  @media (min-width: $screen-x-lg) {
    width: 7.2rem;
    height: 12rem;
  }
}

.char {
  visibility: hidden;
}

.headline h1 {
  //position: absolute;
  word-break: keep-all;
}

.headline {
  visibility: hidden;
  position: relative;
  margin-bottom: 6rem;

  @media (min-width: $screen-md) {
    margin-bottom: 0;
  }
}