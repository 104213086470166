body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $c-black;
  letter-spacing: $ls-base;
  font-family: $font-default;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-weight: $fw-bold;
  color: $c-blue;
}

h1, .h1 {
  font-size: $fs-h1 - 76px;

  @media (min-width: $screen-sm) {
    font-size: $fs-h1 - 48px;
  }

  @media (min-width: $screen-md) {
    font-size: $fs-h1 - 36px;
  }

  @media (min-width: $screen-lg) {
    font-size: $fs-h1 - 16px;
  }

  @media (min-width: $screen-x-lg) {
    font-size: $fs-h1;
    letter-spacing: -1.0rem;
  }
}

h2, .h2 {
  margin-bottom: $fs-h2;
}

h3, .h3 {
  margin-bottom: $fs-h3;
  margin-top: $fs-h3 * 2;
}

h4, .h4 {

}

li,
p,
td {
  font-weight: $fw-normal;
}

b,
strong {
  font-weight: $fw-bold;
}

em,
i {
  font-style: inherit;
}

a {
  color: $c_blue;
  font-weight: $fw-bold;

  &:hover {
    text-decoration: none;
  }
}

p {
  font-size: $fs-small;

  @media (min-width: $screen-md) {
    font-size: $fs-default;
  }

  a {
    text-decoration: none;
  }
}

.headline {
  h1 {
    margin: 0;
  }
}
