@font-face {
  font-family: 'TSTAR Mono';
  src:    url('../fonts/tstarmono/tstarmonoroundw00-bold-webfont.woff2') format('woff2'),
          url('../fonts/tstarmono/tstarmonoroundw00-bold-webfont.woff') format('woff'),
          url('../fonts/tstarmono/tstarmonoroundw00-bold-webfont.ttf') format('truetype'),
          url('../fonts/tstarmono/tstarmonoroundw00-bold-webfont.svg#tstar_mono_round_w00_boldRg') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'TSTAR Mono';
  src:    url('../fonts/tstarmono/tstarmonoroundw00-regular-webfont.woff2') format('woff2'),
          url('../fonts/tstarmono/tstarmonoroundw00-regular-webfont.woff') format('woff'),
          url('../fonts/tstarmono/tstarmonoroundw00-regular-webfont.ttf') format('truetype'),
          url('../fonts/tstarmono/tstarmonoroundw00-regular-webfont.svg#tstar_mono_round_w00_regulaRg') format('svg');
  font-weight: normal;
  font-style: normal;
}
