.refs {
  position: relative;
  height: 45rem;
  margin: 0 2rem;

  @media (min-width: $screen-sm) {
    height: 90rem;
    margin: 0;
  }

  @media (min-width: $screen-md) {
    height: 60rem;
  }

  @media (min-width: $screen-lg) {
    height: 60rem;
  }
}

.refs__image {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  bottom: auto;
  left: 0;
  visibility: hidden;
  @include box-shadow(0 24px 48px -12px rgba(0, 0, 0, 0.1));

  @media (min-width: $screen-lg) {
    top: auto;
    bottom: 0;
  }
}