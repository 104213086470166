.lang-switch {
  visibility: hidden;
  position: fixed;
  bottom: 2rem;
  left: 50%;
  z-index: 99;
  @include translate(-50%, 0);

  @media (min-width: $screen-md) {
    position: absolute;
    top: 4rem;
    right: 3rem;
    left: auto;
    @include translate(0, 0);
    display: block;
    margin-top: 0;
    visibility: visible;
  }
}

.lang-switch__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.lang-switch__item {
  float: left;
  margin: 0 0.75rem;

  &.active {
    .lang-switch__link {
      &:before {
        width: 85%;
      }
    }
  }
}

.lang-switch__link {
  position: relative;
  font-size: $fs-small;
  text-transform: uppercase;
  font-weight: $fw-bold;
  padding: 0.25rem 0;
  letter-spacing: 0.25rem;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: $c-blue;
  }

  &:focus,
  &:active {
    text-decoration: none;
    outline: none;
  }
}