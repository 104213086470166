.btn--link {
  background-color: transparent;
  border: none;
  font-weight: $fw-bold;
  padding: 0.5rem 0;
  outline: none !important;
  border-radius: 0;
  border-bottom: 2px solid transparent;
  @include transition($t-short $ease-out-quad);

  &:focus,
  &:active,
  &:hover {
    border-color: $c-black;
    @include box-shadow(none);
  }
}

.btn--sm {
   font-size: $fs-small;
   padding: 0.25rem 0;
 }

.btn--cta {
  background-color: transparent;
  border: 2px solid $c-blue;
  font-weight: $fw-bold;
  padding: 1.5rem 3rem;
  outline: none !important;
  border-radius: 0;
  @include transition($t-short $ease-out-quad);

  &:focus,
  &:active,
  &:hover {
    background-color: $c-blue;
    color: $c-white;
    @include box-shadow(none);
  }
}

.btn--custom {
  @media (min-width: $screen-desktop) {
    margin-top:  16rem;
  }
}