.form-group {
  visibility: hidden; // because of *animation*
  position: relative;
  margin-bottom: 5rem;
  cursor: pointer;

  label.control-label {
    position: absolute;
    top: 2rem;
    left: 0;
    z-index: 9;
    font-weight: $fw-normal;
    letter-spacing: 0;
    @include transition($t-short $ease-out-quad);
  }

  &.active {

    label.control-label {
      top: -2.0rem;
      transform-origin: left center;
      @include scale(0.75);
      opacity: 0.5;
    }

    .input-group {

      &:after {
        width: 100%;
      }
    }
  }

  // Validation
  .help-block {
    font-size: $fs-small;
  }

  &.has-error {

    label.control-label {
      color: $c-black;
    }

    .input-group {
      &:after {
        background-color: $brand-danger;
      }
    }

    .form-control {
      @include box-shadow(none);
    }
  }

  &.has-success {

    label.control-label {
      color: $c-black;
    }

    .form-control {
      @include box-shadow(none);
    }
  }
}

.input-group {
  width: 100%;
  position: relative;

  &:after {
    content: "";
    width: 0;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $c-black;
    z-index: 9;
    @include transition($t-short $ease-out-quad);
  }
}

input.form-control {
  border: none;
  height: 5rem;
  padding: 0;
  color: $c-black;
  letter-spacing: $ls-base;
  @include box-shadow(none);

  &:focus {
    outline: none !important;
    @include box-shadow(none);
  }
}

textarea.form-control {
  border: none;
  @include box-shadow(none);
  padding: 0.5rem 0 0 0;
  resize: none;
  color: $c-black;

  &:focus {
    outline: none !important;
    @include box-shadow(none);
  }
}