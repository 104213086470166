.branding {
  position: relative;
  display: block;
  width: auto;
  height: auto;
  margin: 2rem 3rem 0 3rem;
  text-align: center;
  z-index: 99;

  @media (min-width: $screen-md) {
    margin: 4rem 3rem 2rem 3rem;
  }
}

.logo {
  background: url('../images/logo-uwe_schneider.svg') no-repeat center center / contain;
  width: 100%;
  height: auto;
  display: table;
  overflow: hidden;
  color: transparent;
  margin: auto;

  @media (min-width: $screen-sm) {
    width: 50%;
  }

  @media (min-width: $screen-md) {
    width: 100%;
    height: 2.7rem;
  }

  &:hover,
  &:focus,
  &:active {
    color: transparent;
  }
}