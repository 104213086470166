footer.footer {
  margin-top: 16rem;
  padding: 4rem 0;
  border-top: 1px solid $c-black;
}

.footer__links {
  li {
    float: left;
    list-style: none;
    margin: 0 3rem 0 0;

    a {
      padding: 0.5rem 0;
      display: block;
    }
  }
}