#die-3c {

  .headline {

    h1 {

      @media (min-width: $screen-x-lg) {
        font-size: 200px;
      }
    }
  }

  .cursor {

    @media (min-width: $screen-x-lg) {
      width: 12rem;
      height: 20rem;
    }
  }

  .content {
    margin-top: 6rem;

    @media (min-width: $screen-lg) {
      margin-top: 8rem;
    }
  }

  .creation {
    margin-top: 6rem;

    @media (min-width: $screen-lg) {
      margin-top: 8rem;
    }
  }
}
