#leben {

  .headline {
    margin-bottom: 0;
  }

  .copy {
    margin-top: 6rem;
  }
}


.sender {
  visibility: hidden;
  text-align: right;
  font-size: $fs-small;
  font-weight: $fw-bold;
  padding-right: 25%;
  margin-top: 2rem;
  margin-bottom: 6rem;

  @media (min-width: $screen-md) {
    margin-bottom: 0;
  }
}

.profile {
  position: relative;
  display: inline-block;

  &.active {
    .profile__curtain {
      width: 0;
    }
  }
}

.profile__image {
  width: 100%;
  height: auto;
  position: relative;
}

.profile__curtain {
  position: absolute;
  z-index: 2;
  top: 0;
  width: 100%;
  right: 0;
  height: calc(100% + 2px);

  &--outer {
    background-color: $c-white;
    z-index: 3;
    @include transition($t-medium $ease-out-quad);
  }

  &--inner {
    background-color: $c-blue;
    @include transition($t-medium $ease-out-quad);
    @include transition-delay($t-medium);
  }
}

