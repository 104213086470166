.parallax {
    display: block;
    width: 100vw;
    height: 35vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    margin-top: 15vh;

    @media (min-width: $screen-tablet) {
        background-attachment: fixed;
        background-size: cover;
        height: 75vh;
        margin-top: 25vh;
    }

    &--slim {
        height: 20vh;

        @media (min-width: $screen-tablet) {
            height: 25vh;
        }
    }
}