.nav-aside {

  @media (max-width: $screen-sm-max) {
    visibility: hidden;
    background-color: $c_white;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9;
    bottom: 0;
    display: table;
  }
}


nav.nav {
  display: table-cell;
  vertical-align: middle;
  z-index: 999;

  @media (min-width: $screen-md) {
    display: table;
    //margin: auto;
    text-align: center;
    position: fixed;
    top: 2rem;
    left: 2rem;
  }
}

.nav__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav__item {
  position: relative;
  display: table;
  margin: 2rem auto;

  @media (min-width: $screen-md) {
    margin: 0;
  }

  &.active {

    .nav__link {
      color: $c-white;
      opacity: 1;
      height: auto;

      &:before {
        height: 100%;
        width: 100%;
      }
    }
  }

  &:hover {

    .nav__link {
      color: $c-white;
      opacity: 1;
      height: auto;

      &:before {
        height: 100%;
        width: 100%;
        opacity: 0.5;
      }
    }
  }
}

.nav__link {
  position: relative;
  cursor: pointer;
  color: $c-white;
  font-size: $fs-small;
  font-weight: $fw-bold;
  letter-spacing: $ls-nav;
  padding: 0.5rem 2rem;
  display: block;
  text-decoration: none !important;
  @include transition($t-medium $ease-out-quad);

  @media (min-width: $screen-md) {
    color: transparent;
  }

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    @include translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: $c-blue;
    z-index: -1;
    @include transition($t-medium $ease-out-quad);

    @media (min-width: $screen-md) {
      left: 0;
      @include translate(0, -50%);
      width: 50%;
      height: 0.2rem;
    }
  }

  &:focus,
  &:hover {
    color: transparent;
    outline: none !important;
  }
}


// leave spacing for aside navigation
.container-fluid {
    @media (min-width: $screen-md) {
      margin-left: 25rem;
    }
}


// Burger Icon

.btn-nav {
  opacity: 0;
  position: relative;
  margin: 0 auto;
  display: table;
  width: 50px;
  height: 50px;
  overflow: hidden;
  z-index: 999;

  @media screen and (min-width: $screen-md) {
    display: none;
  }
}

body.menu-open {
  header.header {
    position: fixed;
    top: 0;
    z-index: 99;
  }
}