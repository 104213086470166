.contactform__result {
  display: none;

  &.contactform__result--error {
    color: $c-alert;
  }
}

.contact-action {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

a.linkedIn {
  display: block;
  margin-left: 1rem;

  >img {
    height: 62px;
    width: auto;
    transition: $t-short $ease-default;

    &:hover {
      transform: scale(0.95);
    }
  }
}